<template>
    <div :class="defaultActive !== '/' ? 'active' : ''" class="shop_class" v-if="isMenu">
        <div class="content">
            <div class="class_group" @mouseleave="hiddenClass">
                <div class="title" @mousemove="showClassOne">
                    <span class="text">全部商品分类</span>
                    <img src="../../../assets/img/nav_all.png" class="icon" />
                </div>

                <div :class="isShowClassOne ? 'class_one on' : 'class_one'">
                    <ul class="list">
                        <li v-for="(item, index) in menuData" :key="index" class="one">
                            <div class="icon">
                                <template v-if="Number(index) === 0">
                                    <img src="../../../assets/img/menuIcon/menu01.png" class="default" />
                                    <img src="../../../assets/img/menuIcon/menu01_hover.png" class="hover" />
                                </template>

                                <template v-else-if="Number(index) === 1">
                                    <img src="../../../assets/img/menuIcon/menu02.png" class="default" />
                                    <img src="../../../assets/img/menuIcon/menu02_hover.png" class="hover" />
                                </template>

                                <template v-else-if="Number(index) === 2">
                                    <img src="../../../assets/img/menuIcon/menu03.png" class="default" />
                                    <img src="../../../assets/img/menuIcon/menu03_hover.png" class="hover" />
                                </template>

                                <template v-else-if="Number(index) === 3">
                                    <img src="../../../assets/img/menuIcon/menu04.png" class="default" />
                                    <img src="../../../assets/img/menuIcon/menu04_hover.png" class="hover" />
                                </template>

                                <template v-else-if="Number(index) === 4">
                                    <img src="../../../assets/img/menuIcon/menu05.png" class="default" />
                                    <img src="../../../assets/img/menuIcon/menu05_hover.png" class="hover" />
                                </template>

                                <template v-else>
                                    <img src="../../../assets/img/menuIcon/menu06.png" class="default" />
                                    <img src="../../../assets/img/menuIcon/menu06_hover.png" class="hover" />
                                </template>
                            </div>
                            <div class="info">
                                <template v-for="(menu, i) in item.title">
                                    <template v-if="Number(i) > 0">
                                        <span class="deli" :key="i + 'i'">｜</span>
                                    </template>
                                    <a href="javascript:void(0)" :key="i + 'a'" class="one" @click="toGoodsList('1','1', item.id, menu.categoryId, menu.name)">{{menu.name}}</a>
                                </template>
                            </div>
<!--                            <i class="el-icon-arrow-right one"></i>-->

                            <!--                        <div class="class_two">-->
                            <!--                            <div class="itemTwo" v-for="(two, i) in item.treeList" :key="i">-->
                            <!--                                <a class="nameTwo" href="javascript:void(0)" v-for="(menu, j) in two.title" :key="j" @click="toGoodsList('1','2', two.id, menu.categoryId, menu.name)">{{menu.name}}</a>-->
                            <!--                                <i class="el-icon-arrow-right two"></i>-->
                            <!--                                <ul class="listTwo">-->
                            <!--                                    <template v-for="(three) in two.treeList">-->
                            <!--                                        <li class="two" v-for="(menu, j) in three.title" :key="j">-->
                            <!--                                            <a href="javascript:void(0)" class="two" @click="toGoodsList('1','3', three.id, menu.categoryId, menu.name)">{{menu.name}}</a>-->
                            <!--                                        </li>-->
                            <!--                                    </template>-->
                            <!--                                </ul>-->
                            <!--                            </div>-->
                            <!--                        </div>-->
                        </li>
                    </ul>
                </div>
            </div>

            <ul class="menu">
                <li :class="{on: defaultActive === '/index'}">
                    <a href="javascript:void(0)" @click="defaultActive === '/index' ? '' : $router.push('/index')">首页</a>
                </li>
                <li v-for="(item, index) in navData" :key="index" :class="{on: defaultActive === item.url.split('#')[1]}">
                    <a href="javascript:void(0)" @click="toGoNav(item.url, item.type)">{{item.title}}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "LayoutClass",
    inject: ['reload'],
    props: {
      menuData: {
        type: Array,
        default: () => {
          return []
        }
      },
      navData: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data () {
      return {
        isListPage: false
      }
    },
    computed: {
      ...mapState({
        defaultActive: state => state.user.defaultActive,
        isMenu: state => state.user.isMenu,
        isShowClassOne: state => state.user.isShowClassOne,
      })
    },
    methods: {
      showClassOne () {
        this.$store.commit('changeIsShowClassOne', true)
      },
      hiddenClass () {
        if (this.defaultActive === '/index') {
          this.$store.commit('changeIsShowClassOne', true)
        } else {
          this.$store.commit('changeIsShowClassOne', false)
        }
      },
      toGoNav (url, type) {
        if (type === 1) {
          window.open(url)
        } else {
          window.location.href = url
        }
      },
      toGoodsList (type, level, id, categoryId, name) {
        const { href } = this.$router.resolve({path: '/shop/list', query: {type: type, level: level, id: id, categoryId: categoryId, name: name, page: '1'}});
        window.location.href = href;
        this.reload();
      }
    }
  }
</script>
